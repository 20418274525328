import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  leftMenuNavigation = 'mentees';
  currentRoute: Subject<'mmDetail' | 'dashboard' | 'all-emails' | 'all-feedbacks' | 'profile'> = new Subject();
  detailNavigation: 'application' | 'emails' | 'feedbacks' | 'change-logs' | 'settings' = 'application';
  constructor() {}
}
